import React from "react"
import { Link,graphql } from 'gatsby'

import Layout from "../../components/layout"
import Next from "../../components/next"
import Tooltip from "../../components/tooltip"

const Page = ({data}) => (
  <Layout title="Smartix" keywords={[`rail api`, `train ticket`, `api`]} submenu={data.allRailapisubmenuJson.edges}>
    <content>
      <h1>After Sales Service</h1>

      <p><Link to="/smartix/" className="printLink">smartix</Link> offers a range of after sales services which you can use to 
      amend or cancel previous bookings. Another useful feature is the ability to modify a <Tooltip>ToD</Tooltip> booking to 
      allow your customer to collect their tickets in the event that payment card used to purchase from you is not available
      when they collect their tickets.</p>

      <p>We provide a simple to use refund application that you can use to retrieve ticket details and request a cancellation. 
      We manage the cancellation and reporting to <Tooltip>RDG</Tooltip>. You then manage the refund of the money.</p>

      <Next submenu={data.allRailapisubmenuJson.edges}/>

    </content>
  </Layout>
)

export const query = graphql`
  query {
    allRailapisubmenuJson {
      edges {
        node {
          title
          path
        }
      }
    }
  } 
`

export default Page